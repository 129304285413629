.admin-links {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.admin-links button {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #58a731;
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

.admin-links button:hover {
  text-decoration: none;
}

.admin-section-title {
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 40px;
}

.table {
  margin-top: 20px;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle;
}

.logout-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 140px;
}

@media (max-width: 768px) {
  .admin-links {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .admin-links button {
    flex: 1 1 auto;
    text-align: center;
  }

  .logout-panel {
    justify-content: center;
  }
}
