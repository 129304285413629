.navbar {
background: linear-gradient(to left, #f4430d, #1741ab);
}


.brand {
  display: flex;
  align-items: center;
}

.brand-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.brand-name {
  font-size: 1.75rem;
  font-weight: bold;
  font-family: "Exo 2", sans-serif;
}

.nav-link {
  font-size: 1rem;
  margin: 0 10px;
}

.cart-icon {
  width: 25px;
  height: 25px;
   filter: brightness(0) invert(1) sepia(0) saturate(100%) hue-rotate(0deg);

  max-width: 35px;
}
.cart-cont:hover {
  color:red;
}

.cart-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 0.8rem;
  position: absolute;
  top: 5px;
  right: 10;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<son css
<<<<<<<<<<<<<<<< */


.btn-register {
  display: block;
  position: relative;
  margin-left: 60px;
  padding: 10px 20px;
  border: 2px solid #00FFFF !important;
  border-radius: 13px;
  background-color: transparent;
  color: #00FFFF;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  
}

.btn-register:hover {
  background-color:#4A4287;
  color: white;
  box-shadow: 0 4px 15px #00FFFF;
  transform: scale(1.05);
}

.navlink a {
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: 600;
}




@media (max-width: 768px) {
  .brand-name {
  font-size: 1.25rem;
  font-weight: bold;
  font-family: "Exo 2", sans-serif;
  }

  .nav-link {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  .btn-register {

  position: relative;
  margin-left: 1px;
  padding: 1px 2px;
  border: 2px  #00FFFF !important;
  border-radius: 13px;
  background-color: transparent;
  color: #00FFFF;
  text-align: center;
  text-decoration: none;

}

}
