@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');
body{
  font-family: "Exo 2", sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('./Picture//background.jpg'); 
  /* background-size: cover; */
  min-height: 100vh;

}

/* .exo-2-<uniquifier> {
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */