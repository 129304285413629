.whatsapp {
  position: fixed;
  bottom: 130px; 
  right: 15px; 
  z-index: 100; 
}

.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25D366; 
  color: #fff; 
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.whatsapp-icon {
  font-size: 1cm; 
  margin-right: 8px;
}

